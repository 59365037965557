import { createSlice } from "@reduxjs/toolkit";
import {
  changeUserStatus,
  getUserDetails,
  getUsers,
} from "../actions/adminActions";
import { toast } from "react-toastify";

const initialState = {
  users: null,
  totalUsers: null,
  userDetails: null,
};

export const userMgmtSlice = createSlice({
  name: "userMgmtSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.users = payload.data.users;
          state.totalUsers = payload.data.totalUsers;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.userDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(changeUserStatus.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.users = state.users?.map((user) =>
            user?._id == payload?.data?._id ? payload?.data : user
          );
          toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default userMgmtSlice.reducer;
