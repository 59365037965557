import GoogleMapReact from "google-map-react";

const GoogleMap = ({ long, lat }) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const center = {
    lat: lat,
    lng: long,
  };

  return (
    <div style={{ height: "200px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        // defaultCenter={defaultCenter}
        center={center}
        defaultZoom={13}
      >
        <Marker lat={lat} lng={long} />
      </GoogleMapReact>
    </div>
  );
};
// const Marker = ({ text }) => <div className="marker">{text}</div>;
const Marker = () => (
  // <div className="marker">
  //   Marker <img src={require("../../images/location-icon.svg").default} />
  // </div>
  <img className="marker" src={require("../../images/location-icon.svg").default} />
);
export default GoogleMap;
