import { configureStore } from "@reduxjs/toolkit";
import userMgmtReducer from "./reducers/userMgmtSlice";
import userVerifyReducer from "./reducers/userVerifySlice";
import reportReducer from "./reducers/reportsSlice";
import notificationReducer from "./reducers/notificationSlice";
import supportReducer from "./reducers/supportSlice";
import dashboardSlice from "./reducers/dashboardSlice";
import manageAffiliates from "./reducers/manageAffiliates";
import  AffilatesMarketSlice  from "./reducers/affiliateMarket";

export const store = configureStore({
  reducer: {
    userMgmtSlice: userMgmtReducer,
    userVerifySlice: userVerifyReducer,
    reportsSlice: reportReducer,
    notificationSlice: notificationReducer,
    supportSlice: supportReducer,
    dashboard: dashboardSlice,
    manageAffiliatesdata:manageAffiliates,
    marketAffiliate:AffilatesMarketSlice,
  
  },
});
