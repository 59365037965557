import React, { useContext, useEffect, useRef } from "react";
import Layout from "../Layout/Layout";
import {
  Container,
  Row,
  Card,
  Col,
  Image,
  InputGroup,
  Form,
} from "react-bootstrap";
import userimage from "../../images/userimg.png";
import { FiSend } from "react-icons/fi";
import send from "../../images/send.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSupportChat } from "../../redux/actions/adminActions";
import moment from "moment";
import { SocketContext } from "../../context/ChatContext";
import { Formik } from "formik";
import { jwtDecode } from "jwt-decode";
import { updateSupportChat } from "../../redux/reducers/supportSlice";

export default function SupportDetail() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL + "/";
  const dispatch = useDispatch();
  const { id } = useParams();
  const supportChats = useSelector((state) => state.supportSlice.supportChats);
  const userdetails = useSelector((state) => state.supportSlice.userdetails);
  const socket = useContext(SocketContext);
  const chatboxref = useRef(null);

  useEffect(() => {
    dispatch(getSupportChat(id));
    socket.emit("join_room", { room_id: id });
    setTimeout(() => {
      handleScrolltoLast();
    }, 500);
  }, []);

  useEffect(() => {
    socket.on("recv_support_message", (messageObj) => {
      dispatch(updateSupportChat(messageObj));
      setTimeout(() => {
        handleScrolltoLast();
      }, 100);
    });
  }, [socket]);

  const handleSendMessage = (message) => {
    const { _id } = jwtDecode(sessionStorage.getItem("token"));

    const messageObj = {
      message,
      sender_id: _id,
      support_id: id,
      sender_type: 0,
    };

    socket.emit("send-support", messageObj);
    setTimeout(() => {
      handleScrolltoLast();
    }, 100);
  };

  const handleScrolltoLast = () => {
    chatboxref.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  return (
    <>
      <Container fluid>
        <Row className="justify-content-center">
          <Col xxl={8} xl={10} lg={10}>
            <Card className="border-0 p-4  vh-75 position-relative rounded">
              <h4>Support Details</h4>
              <hr />
              <div className="msg-body p-3">
                <ul ref={chatboxref}>
                  {supportChats?.length > 0 &&
                    supportChats?.map((chat) =>
                      chat.sender_type === 1 ? (
                        <li className="sender">
                          <span className="time">
                            <Image
                              className="support-chat-img"
                              src={BASE_URL + userdetails?.userimage}
                              roundedCircle
                            />
                            <strong className="text-primary-clr">
                              {userdetails?.username}
                            </strong>
                            <span>
                              {moment(chat?.createdAt).format("hh:mm a")}
                            </span>
                          </span>
                          <br /> <p>{chat?.message}</p>
                        </li>
                      ) : (
                        <li className="repaly">
                          <span className="time">
                            <strong className="text-primary-clr">You</strong>
                            <span>
                              {moment(chat?.createdAt).format("hh:mm a")}
                            </span>
                          </span>
                          <br /> <p>{chat?.message}</p>
                        </li>
                      )
                    )}
                </ul>
              </div>

              <Formik
                initialValues={{ message: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.message) {
                    errors.message = "Required";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSendMessage(values.message);
                  resetForm();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="send-box">
                      <InputGroup className="mb-3">
                        <Form.Control
                          className="send-feild"
                          placeholder="Type your message here..."
                          aria-label="send"
                          aria-describedby="basic-addon2"
                          type="text"
                          name="message"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                        />

                        <InputGroup.Text
                          id="basic-addon2"
                          as="button"
                          type="submit"
                        >
                          <Image src={send} />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </form>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
