import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addNotification,
  deleteNotification,
  getNotifications,
} from "../actions/adminActions";

const initialState = {
  notifications: null,
};

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.notifications = payload.data;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(addNotification.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.notifications.push(payload.data);
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteNotification.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.notifications = state.notifications?.filter(
            (not) => not?._id != payload.data?._id
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default notificationSlice.reducer;
