import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Badge,
  DropdownButton,
  Stack,
  Dropdown,
} from "react-bootstrap";
import { FiArrowDown } from "react-icons/fi";
import userimg from "../images/userimg.png";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeUserStatus, getUsers } from "../redux/actions/adminActions";
import { Search } from "semantic-ui-react";
import Pagination from "../components/elements/Pagination";

export default function User() {
  const dispatch = useDispatch();
  const limit = 5;
  const users = useSelector((state) => state.userMgmtSlice.users);
  const totalUsers = useSelector((state) => state.userMgmtSlice.totalUsers);
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getUsers({ page, limit, search: searchData }));
  }, [page, searchData]);

  const handleEnter = (e) => {
    if (e.key == "Enter") {
      setSearchData(search);
    }
  };

  const handleStatusChange = (id, status) => {
    dispatch(changeUserStatus({ id, status }));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Stack className="mb-3 " direction="horizontal" gap={1}>
            {/* <div className="p-2 ms-auto"><Button className="text-primary-clr" size="lg" variant=""><FiFilter/></Button>{' '}</div> */}
            <div className="p-2 ms-auto">
              <Button
                className="text-primary-clr btn-16"
                size="lg"
                variant="light"
              >
                <FiArrowDown /> Download
              </Button>
            </div>
          </Stack>
          <Card className="border-0 p-4 rounded-card user-section custom-table-height">
            <Stack direction="horizontal" gap={3}>
              <div className="p-2">
                <h3>User Management</h3>
              </div>
              <div className="p-2 ms-auto">
                <div className="position-relative m-2">
                  <Form.Control
                    type="search"
                    placeholder="Search..."
                    className="serch-feild"
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={handleEnter}
                  />
                  <FiSearch className="searchicon2" />
                </div>
              </div>
            </Stack>
            <Table responsive variant="" className="border-0 custom-table mt-3">
              <thead className="first-td">
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Location</th>
                  <th>Verified/ Unverified status</th>
                  <th>Profile Image</th>
                  <th>Gender</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users?.length > 0 ? (
                  users?.map((user, i) => {
                    return (
                      <tr key={user?._id}>
                        <td>{i + 1 + (page - 1) * limit}</td>
                        <td className="user-name-ac">
                          <Link to={`/admin/userdetail/${user?._id}`}>
                            {user?.full_name}
                          </Link>
                        </td>
                        <td>{user?.age}</td>
                        <td>
                          <p className="text-short">{user?.address}</p>
                        </td>
                        <td>
                          <Badge
                            bg={
                              user?.admin_verified == 1 ? "success" : "warning"
                            }
                          >
                            {user?.admin_verified == 1
                              ? "VERIFIED"
                              : "UN-VERIFIED"}
                          </Badge>
                        </td>
                        <td>
                          <img
                            src={BASE_URL + "/" + user?.profile_image}
                            alt="user image"
                            className="rounded-circle profile-image"
                          />
                        </td>
                        <td>
                          {user?.gender == 0
                            ? "Female"
                            : user?.gender == 1
                            ? "Male"
                            : user?.gender == 2 && "Others"}
                        </td>
                        <td>
                          <Badge bg="primary">
                            {user?.banned == 0
                              ? "ACTIVE"
                              : user?.banned == 1
                              ? "TEMPORARILY BANNED"
                              : user?.banned == 2 && "PERMANENTLY BANNED"}
                          </Badge>
                        </td>
                        <td>
                          <DropdownButton
                            id="dropdown-item-button "
                            title={
                              user?.banned == 0
                                ? "Active"
                                : user?.banned == 1
                                ? "Temporarily Banned"
                                : user?.banned == 2 && "Permanently Banned"
                            }
                            className="border-0 secondary-drop-area dropdown-cmn"
                          >
                            {[
                              { key: 0, text: "Active" },
                              { key: 1, text: "Temporarily Banned" },
                              { key: 2, text: "Permanently Banned" },
                            ]?.map((status) => {
                              return user?.banned == status?.key ? (
                                <Dropdown.ItemText>
                                  {status?.text}
                                </Dropdown.ItemText>
                              ) : (
                                <Dropdown.Item
                                  onClick={() =>
                                    handleStatusChange(user?._id, status?.key)
                                  }
                                  as="button"
                                >
                                  {status?.text}
                                </Dropdown.Item>
                              );
                            })}
                          </DropdownButton>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>No Users yet</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination
              totalStuff={totalUsers}
              limit={limit}
              setPage={setPage}
            />
          </Card>
        </Row>
      </Container>
    </>
  );
}
