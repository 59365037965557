import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../services/adminApi";

const dashboard = createAsyncThunk("dashboard", async () => {
  const { data } = await adminApi.get("/dashboard");
  return data;
});

const getUsers = createAsyncThunk(
  "getUsers",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getUsers?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const getUserDetails = createAsyncThunk("getUserDetails", async (id) => {
  const { data } = await adminApi.get(`/getUserDetails/?user_id=${id}`);
  return data;
});

const getVerificationUsers = createAsyncThunk(
  "getVerificationUsers",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getVerificationUsers?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const adminVerifyUser = createAsyncThunk(
  "adminVerifyUser",
  async (verifyData) => {
    const { data } = await adminApi.post("/adminVerifyUser", verifyData);
    return data;
  }
);

const getReports = createAsyncThunk(
  "getReports",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getReports?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const deleteReport = createAsyncThunk("deleteReport", async (id) => {
  const { data } = await adminApi.delete(`/deleteReport?id=${id}`);
  return data;
});

const changeUserStatus = createAsyncThunk(
  "changeUserStatus",
  async (statusObj) => {
    const { data } = await adminApi.post("/changeUserStatus", statusObj);
    return data;
  }
);

const changeReportUserStatus = createAsyncThunk(
  "changeReportUserStatus",
  async (statusObj) => {
    const { data } = await adminApi.post("/changeUserStatus", statusObj);
    return data;
  }
);

const addNotification = createAsyncThunk("addNotification", async (notObj) => {
  const { data } = await adminApi.post("/addNotification", notObj);
  return data;
});

const getNotifications = createAsyncThunk("getNotifications", async () => {
  const { data } = await adminApi.get("/getNotifications");
  return data;
});

const deleteNotification = createAsyncThunk(
  "deleteNotification",
  async (id) => {
    const { data } = await adminApi.delete(`/deleteNotification?id=${id}`);
    return data;
  }
);

const getSupports = createAsyncThunk("getSupports", async ({ page, limit, search }) => {
  const { data } = await adminApi.get(`/getSupports?page=${page}&limit=${limit}&search=${search}`);
  return data;
});

const changeSupportStatus = createAsyncThunk(
  "changeSupportStatus",
  async (statusObj) => {
    const { data } = await adminApi.post("/changeSupportStatus", statusObj);
    return data;
  }
);

const getSupportChat = createAsyncThunk("getSupportChat", async (id) => {
  const { data } = await adminApi.get(`/getSupportChat?id=${id}`);
  return data;
});

const deleteUserAccount = createAsyncThunk("deleteUserAccount", async (id) => {
  const { data } = await adminApi.delete(`/deleteUserAccount?user_id=${id}`);
  return data;
});

const deactivateUserAccount = createAsyncThunk(
  "deactivateUserAccount",
  async (obj) => {
    const { data } = await adminApi.put("/deactivateUserAccount", obj);
    return data;
  }
);

const closeReport = createAsyncThunk("closeReport", async (obj) => {
  const { data } = await adminApi.put("/closeReport", obj);
  return data;
});


export const getAffiliateRequest = createAsyncThunk("getAffiliateRequest", async ({ page, limit, search }) => {
  const { data } = await adminApi.get(`/getAffiliateRequest?page=${page}&limit=${limit}&name=${search}`);
  return data;
});

export const getAffilatesDetails = createAsyncThunk("getAffilatesDetails", async (id) => {
  const { data } = await adminApi.get(`/afffiliateRequestById?requestId=${id}`);
  return data;
});

export const acceptRejectAffiliate = createAsyncThunk(
  "acceptRejectAffiliate",
  async (statusObj) => {
    const { data } = await adminApi.post("/acceptRejectAffiliate", statusObj);
    return data;
  }
);

export const submitAffiliateForm = createAsyncThunk(
  "submitAffiliateForm",
  async (statusObj) => {
    const { data } = await adminApi.post("/submitAffiliateForm", statusObj);
    return data;
  }
);

export const createAffiliateMarketing = createAsyncThunk(
  "createAffiliateMarketing",
  async (statusObj) => {
    const { data } = await adminApi.post("/createAffiliateMarketing", statusObj);
    return data;
  }
);

export const getAffiliateMarketing = createAsyncThunk("getAffiliateMarketing", async ({ page, limit, search }) => {
  const { data } = await adminApi.get(`/getAffiliateMarketing?page=${page}&limit=${limit}&name=${search}`);
  return data;
});

export const deleteAffiliate = createAsyncThunk("deleteUserAccount", async (id) => {
  const { data } = await adminApi.post(`/deleteAffiliateMarketing`,id);
  return data;
});





export {
  dashboard,
  getUsers,
  getUserDetails,
  getVerificationUsers,
  adminVerifyUser,
  getReports,
  deleteReport,
  changeUserStatus,
  addNotification,
  getNotifications,
  deleteNotification,
  getSupports,
  changeSupportStatus,
  changeReportUserStatus,
  getSupportChat,
  deleteUserAccount,
  deactivateUserAccount,
  closeReport,
};
