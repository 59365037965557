import React, { useEffect } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import idcard from "../images/idcard.png";
import selfe from "../images/selfe.png";
import { FiSearch } from "react-icons/fi";
import Layout from "../components/Layout/Layout";
import Stack from "react-bootstrap/Stack";
import { useDispatch, useSelector } from "react-redux";
import { getVerificationUsers } from "../redux/actions/adminActions";

export default function AddGift() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col xxl="6" xl="7" lg="8" className="mx-auto">
            <Card className="border-0 rounded-card gift-section">
              <div className="gift-form">
                <h3 className="text-center">Add Gift</h3>

                <div className="image-upload-gift">
                  <img src={require("../images/uplddoicon.svg").default} />
                  <p>Upload</p>
                  <input type="file" className="upload-show" />
                </div>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
