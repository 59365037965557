import React, { useState } from "react";
import { ProSidebarProvider, Menu, MenuItem } from "react-pro-sidebar";
import {
  FiUsers,
  FiAlertOctagon,
  FiGrid,
  FiChevronLeft,
  FiChevronRight,
  FiSliders,
  FiUserCheck,
  FiFlag,
  FiBell,
  FiBox,
} from "react-icons/fi";
import logo from "../../images/logo.svg";
import logo2 from "../../images/logo2.svg";
import Footer from "../Layout/Footer";
import { Link, useLocation } from "react-router-dom";
import { CiWallet } from "react-icons/ci";
import { TbMailMinus } from "react-icons/tb";
import { BiRss, BiWalletAlt } from "react-icons/bi";
import { MdOutlineManageAccounts } from "react-icons/md";
import { BsBriefcase } from "react-icons/bs";

const Sidebar = ({ setCssclass }) => {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    setCssclass(menuCollapse ? "" : "wrap-content");
  };

  const { pathname } = useLocation();

  const isCurrentPage = (...tabs) => {
    return tabs.some((tab) => pathname.includes(tab));
  };

  return (
    <>
      <div id="header" className={` vh-100 ${menuCollapse ? "collapsed" : ""}`}>
        <div className="pro-sidebar">
          <div className="SidebarHeader">
            <div className="logotext px-3 py-4">
              <img src={menuCollapse ? logo2 : logo} alt="logo"></img>
            </div>
            <div className="closemenu" onClick={menuIconClick}>
              {menuCollapse ? (
                <FiChevronRight className="side-icon" />
              ) : (
                <FiChevronLeft className="side-icon" />
              )}
            </div>
          </div>
          <div className="SidebarContent">
            <div className="text-muted mx-4 mb-3">MENU</div>
            <div className="bg-light mx-4 rounded-3">
              <Menu iconShape="square">
                <Link to="/admin/dashboard">
                  <MenuItem
                    active={isCurrentPage("dashboard")}
                    icon={<FiGrid />}
                  >
                    Dashboard
                  </MenuItem>
                </Link>
                <Link to="/admin/analytics">
                  <MenuItem
                    active={isCurrentPage("analytics")}
                    icon={<FiSliders />}
                  >
                    Analytics
                  </MenuItem>
                </Link>
                <Link to="/admin/users">
                  <MenuItem
                    active={isCurrentPage("users", "userdetail")}
                    icon={<FiUsers />}
                  >
                    User Management
                  </MenuItem>
                </Link>
                <Link to="/admin/user-verification">
                  <MenuItem
                    active={isCurrentPage("user-verification")}
                    icon={<FiUserCheck />}
                  >
                    User Verification
                  </MenuItem>
                </Link>
                <Link to="/admin/wallet">
                  <MenuItem
                    className="wallet"
                    active={isCurrentPage("wallet")}
                    icon={<BiWalletAlt />}
                  >
                    Wallet
                  </MenuItem>
                </Link>
                <Link to="/admin/manage-affilates" className="">
                  <MenuItem
                    className="manage-affilates"
                    active={isCurrentPage("manage-affilates")}
                    icon={<MdOutlineManageAccounts />}
                  >
                    Manage Affiliates
                  </MenuItem>
                </Link>

                <Link to="/admin/support" className="support-item">
                  <MenuItem
                    className="support"
                    active={isCurrentPage("support")}
                    icon={<TbMailMinus />}
                  >
                    Support
                  </MenuItem>
                </Link>
                <Link to="/admin/report">
                  <MenuItem active={isCurrentPage("report")} icon={<FiFlag />}>
                    Report
                  </MenuItem>
                </Link>
                <Link to="/admin/notification">
                  <MenuItem
                    active={isCurrentPage("notification")}
                    icon={<FiBell />}
                  >
                    Notification
                  </MenuItem>
                </Link>
                <Link to="/admin/affiliate-marketing">
                  <MenuItem
                    active={isCurrentPage("affiliate-marketing")}
                    icon={<BiRss />}
                  >
                    Affiliate Marketing
                  </MenuItem>
                </Link>
              </Menu>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Sidebar;
