import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { adminVerifyUser, getVerificationUsers } from "../actions/adminActions";

const initialState = {
  users: null,
  totalUsers: null,
};

export const userVerifySlice = createSlice({
  name: "userVerifySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVerificationUsers.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.users = payload.data?.users;
          state.totalUsers = payload.data?.totalUsers;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(adminVerifyUser.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.users = state.users.filter(
            (user) => user?._id != payload?.data?._id
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default userVerifySlice.reducer;
