import React, { useEffect, useState } from "react";
import { Card, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { FiSearch } from "react-icons/fi";
import Stack from "react-bootstrap/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  adminVerifyUser,
  getVerificationUsers,
} from "../redux/actions/adminActions";
import Pagination from "../components/elements/Pagination";
import ViewImage from "../components/elements/ViewImage";
import SimpleModal from "../components/modal/SimpleModal";

export default function UserVerification() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.userVerifySlice.users);
  const totalUsers = useSelector((state) => state.userVerifySlice.totalUsers);
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const limit = 5;
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState(1);
  const [currentImage, setCurrentImage] = useState();
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [verifyObj, setVerifyObj] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(getVerificationUsers({ page, limit, search: searchData }));
  }, [page, searchData]);

  const openImageViewer = (img) => {
    setCurrentImage(img);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage();
    setIsViewerOpen(false);
  };

  return (
    <>
      {isViewerOpen && (
        <ViewImage image={[currentImage]} closeImageViewer={closeImageViewer} />
      )}

      {verifyObj && (
        <SimpleModal
          type={verifyObj.type}
          show={show}
          setShow={setShow}
          data={verifyObj}
        />
      )}

      <Container fluid>
        <Row>
          <Card className="border-0 p-4 rounded-card user-section custom-table-height">
            <Stack direction="horizontal" gap={3}>
              <div className="p-2">
                <h3>User Verification</h3>
              </div>
              <div className="p-2 ms-auto">
                <div className="position-relative m-2">
                  <Form.Control
                    type="search"
                    placeholder="Search..."
                    className="serch-feild"
                  />
                  <FiSearch className="searchicon2" />
                </div>
              </div>
            </Stack>
            <Table responsive variant="" className="border-0 custom-table mt-3">
              <thead className="first-td">
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Surname</th>
                  <th>Id Card</th>
                  <th>Back View</th>
                  <th>Selfie </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users?.length > 0 ? (
                  users?.map((user, i) => {
                    return (
                      <tr key={user._id}>
                        <td>{i + 1 + (page - 1) * limit}</td>
                        <td>{user?.full_name?.split(" ")[0]}</td>
                        <td>{user?.full_name?.split(" ")[1] != "" ? user?.full_name?.split(" ")[1] : "--"}</td>
                        <td>
                          <img
                            src={BASE_URL + "/" + user?.front_view}
                            alt="user image"
                            className="verify-pics"
                            onClick={() =>
                              openImageViewer(BASE_URL + "/" + user?.front_view)
                            }
                          />
                        </td>
                        <td>
                          <img
                            src={BASE_URL + "/" + user?.back_view}
                            alt="user image"
                            className="verify-pics"
                            onClick={() =>
                              openImageViewer(BASE_URL + "/" + user?.back_view)
                            }
                          />
                        </td>
                        <td>
                          <img
                            src={BASE_URL + "/" + user?.selfie}
                            alt="user image"
                            className="verify-pics"
                            onClick={() =>
                              openImageViewer(BASE_URL + "/" + user?.selfie)
                            }
                          />
                        </td>
                        <td>
                          <Stack
                            direction="horizontal"
                            className="table-btn"
                            gap={3}
                          >
                            {user?.admin_verified == 2 && (
                              <>
                                <Button
                                  onClick={() => {
                                    setVerifyObj({
                                      status: 1,
                                      id: user._id,
                                      type: "verifyUser",
                                    });
                                    setShow(true);
                                  }}
                                  as="a"
                                  variant="primary"
                                >
                                  Accept
                                </Button>
                                <Button
                                  onClick={() => {
                                    setVerifyObj({
                                      status: 3,
                                      id: user._id,
                                      type: "dontVerifyUser",
                                    });
                                    setShow(true);
                                  }}
                                  as="a"
                                  variant="success"
                                >
                                  Reject
                                </Button>
                              </>
                            )}
                          </Stack>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td>No Users yet</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination
              totalStuff={totalUsers}
              limit={limit}
              setPage={setPage}
            />
          </Card>
        </Row>
      </Container>
    </>
  );
}
