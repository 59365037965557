import React from "react";
import { Col, Container, Row, Card, Image } from "react-bootstrap";
import dashbordbg from "../images/dasboard-bg.png";
import usericon from "../images/user-icon.png";
import discount from "../images/Discount.png";
import user2 from "../images/2 User.png";
import verifyuser from "../images/Vierify-user.png";
import GenderChart from "../components/elements/GenderChart";
import UserChart from "../components/elements/UserChart";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { dashboard } from "../redux/actions/adminActions";

export default function Dashboard() {
  const dispatch = useDispatch();

  const dash = useSelector((state) => state.dashboard.dashboard);

  useEffect(() => {
    dispatch(dashboard());
  }, []);

  console.log(dash);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xxl="3" xl="3" lg="6" md="12" sm="12">
            <Card className="text-white dasboard-card card-orange p-3">
              <Card.Img src={dashbordbg} alt="Card image" />
              <Card.ImgOverlay>
                <Card.Text className="d-flex justify-content-between">
                  <div className="d-flex flex-column justify-content-between card-txt-w">
                    <Card.Title className="card-title1">Total Users</Card.Title>
                    <div>
                      <Card.Title className="card-title-para">
                        {dash?.totalUsers}
                      </Card.Title>
                      {/* <Card.Text>Average 11k users daily</Card.Text> */}
                    </div>
                  </div>
                  <div className="card-icon-box-1 card-icon-orange">
                    <Image src={usericon} />
                  </div>
                </Card.Text>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col xxl="3" xl="3" lg="6" md="12" sm="12">
            <Card className="text-white dasboard-card card-blue p-3">
              <Card.Img src={dashbordbg} alt="Card image" />
              <Card.ImgOverlay>
                <Card.Text className="d-flex justify-content-between">
                  <div className="d-flex flex-column justify-content-between card-txt-w">
                    <Card.Title className="card-title1">
                      Total Subscribers
                    </Card.Title>
                    <div>
                      <Card.Title className="card-title-para">
                        {"--"}
                      </Card.Title>
                      {/* <Card.Text>Average 11k users daily</Card.Text> */}
                    </div>
                  </div>
                  <div className="card-icon-box-1 card-icon-blue">
                    <Image src={discount} />
                  </div>
                </Card.Text>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col xxl="3" xl="3" lg="6" md="12" sm="12">
            <Card className="text-white dasboard-card card-sky p-3">
              <Card.Img src={dashbordbg} alt="Card image" />
              <Card.ImgOverlay>
                <Card.Text className="d-flex justify-content-between">
                  <div className="d-flex flex-column justify-content-between card-txt-w">
                    <Card.Title className="card-title1">
                      Total Matches
                    </Card.Title>
                    <div>
                      <Card.Title className="card-title-para">
                        {dash?.totalMatches}
                      </Card.Title>
                      {/* <Card.Text>Average 11k users daily</Card.Text> */}
                    </div>
                  </div>
                  <div className="card-icon-box-1 card-icon-sky">
                    <Image src={user2} />
                  </div>
                </Card.Text>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col xxl="3" xl="3" lg="6" md="12" sm="12">
            <Card className="text-white dasboard-card  card-green p-3">
              <Card.Img src={dashbordbg} alt="Card image" />
              <Card.ImgOverlay>
                <Card.Text className="d-flex justify-content-between">
                  <div className="d-flex flex-column justify-content-between card-txt-w">
                    <Card.Title className="card-title1">
                      Total Verified Users
                    </Card.Title>
                    <div>
                      <Card.Title className="card-title-para">
                        {dash?.verifiedUsers}
                      </Card.Title>
                      {/* <Card.Text>Average 11k users daily</Card.Text> */}
                    </div>
                  </div>
                  <div className="card-icon-box-1 card-icon-green">
                    <Image src={verifyuser} />
                  </div>
                </Card.Text>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg="6">
            <Card className="p-4 border-0 rounded-card mb-2">
              <h4>Gender Ratio</h4>
              <GenderChart />
            </Card>
          </Col>

          <Col lg="6">
            <Card className="p-4 border-0 rounded-card mb-2">
              <h4>User Ratio</h4>
              <UserChart />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
