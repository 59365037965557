import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { dashboard, getAffilatesDetails, getAffiliateMarketing, getAffiliateRequest } from "../actions/adminActions";

const initialState = {
  list: null,
//   detail: null,
};

export const AffilatesMarketSlice = createSlice({
  name: "ManageAffilatesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAffiliateMarketing.fulfilled, (state, { payload }) => {
      if (payload.success) {
        state.list = payload.data;
      } else if (!payload.success) {
        toast.error(payload?.message);
      }
    });
    // builder.addCase(getAffilatesDetails.fulfilled, (state, { payload }) => {
    //   if (payload.success) {
    //     state.detail = payload.data;
    //   } 
    // });
  },
});

export default AffilatesMarketSlice.reducer;