const modalData = {
  closeReport: {
    title: "Close the report",
    body: "Are you sure you want to close this report?",
  },
  deleteReport: {
    title: "Delete the report",
    body: "Are you sure you want to delete this report?",
  },
  verifyUser: {
    title: "Accept request",
    body: "Are you sure you want to accept this verification request?",
  },
  dontVerifyUser: {
    title: "Reject request",
    body: "Are you sure you want to reject this verification request?",
  },
};

export { modalData };
