import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  changeSupportStatus,
  getSupportChat,
  getSupports,
} from "../actions/adminActions";

const initialState = {
  supports: null,
  totalSupports: null,
  supportChats: null,
  userdetails: null,
};

export const supportsSlice = createSlice({
  name: "supportsSlice",
  initialState,
  reducers: {
    updateSupportChat: (state, { payload }) => {
      console.log(payload);
      state.supportChats.push(payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupports.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.supports = payload.data?.supports;
          state.totalSupports = payload.data?.totalSupports;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getSupportChat.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.supportChats = payload.data.chats;
          state.userdetails = payload.data.userdetails;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(changeSupportStatus.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.supports = state.supports?.map((sup) =>
            sup?._id == payload?.data?._id ? payload?.data : sup
          );
          toast.success(payload.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export const { updateSupportChat } = supportsSlice.actions;

export default supportsSlice.reducer;
