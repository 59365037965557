import React from "react";
import Layout from "../components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

export default function ChangePassword() {
  return (
    <>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col lg="7" md="auto">
            <Card className="border-0 p-5 rounded-card">
              <h2 className="text-center">Change Password</h2>

              <Form className="change-password-form px-5">
                <Row className="mb-4">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control type="password" placeholder="Old Password" />
                  </Form.Group>
                </Row>
                <Row className="mb-4">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type="password" placeholder="New Password" />
                  </Form.Group>
                </Row>
                <Row className="mb-4">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                    />
                  </Form.Group>
                </Row>
                <Form.Group
                  className="mt-4  mb-5 model-btn text-center"
                  controlId="formGridAddress2"
                >
                  <Button variant="primary" size="lg">
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
