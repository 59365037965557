import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Card,
  Container,
  Row,
  Form,
  Badge,
  Button,
  Table,
  Stack,
  Modal,
  Col,
} from "react-bootstrap";
import { FiSearch, FiPlus } from "react-icons/fi";

import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import {
  createAffiliateMarketing,
  deleteAffiliate,
  getAffiliateMarketing,
} from "../redux/actions/adminActions";
import { toast } from "react-toastify";
import Profile from "../images/Profile-icon.png";
import Pagination from "../components/elements/Pagination";
import { CiGlass } from "react-icons/ci";

export default function AffiliateMarketing() {
  const [show, setShow] = useState(false);
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.marketAffiliate.list);
  console.log(data);

  const [title, setTitle] = useState("");
  const [titleerr, setTitleerr] = useState("");
  const [desc, setDesc] = useState("");
  const [descerr, setDescerr] = useState("");
  const [url, setUrl] = useState("");
  const [urlerr, setUrlerr] = useState("");
  const [img, setimg] = useState(null);
  const [imgerr, setimgerr] = useState(null);
  const [prev, setprev] = useState(null);
  const [flag, setflag] = useState("");
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState(1);
  const limit = 5;

  const handleClose = () => {
    setShow(false);
    setDesc("");
    setTitle("");
    setimg("");
    setprev("");
    setUrl("");
    setDescerr("");
    setUrlerr("");
    setTitleerr("");
    setimgerr("");
  };
  const handleShow = () => setShow(true);

  const handlesave = (e) => {
    e.preventDefault();
    let isvalid = true;

    if (!title) {
      setTitleerr("Required*");
      isvalid = false;
    }
    if (!desc) {
      setDescerr("Required*");
      isvalid = false;
    }
    if (!url) {
      setUrlerr("Required*");
      isvalid = false;
    }
    if (!img) {
      setimgerr("Required*");
      isvalid = false;
    }
    if (isvalid) {
      const formdata = new FormData();
      formdata.append("title", title);
      formdata.append("description", desc);
      formdata.append("url", url);
      formdata.append("marketing_image", img);

      dispatch(createAffiliateMarketing(formdata)).then((res) => {
        if (res?.payload?.success) {
          toast.success(res?.payload?.message);
          setShow(false);
          setDesc("");
          setTitle("");
          setimg("");
          setprev("");
          setUrl("");
          setDescerr("");
          setUrlerr("");
          setTitleerr("");
          setimgerr("");
          setflag(!flag);
        } else {
          toast.error(res?.payload?.error);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getAffiliateMarketing({ page, limit, search: searchData }));
  }, [flag, page, searchData,flag]);

  return (
    <>
      <Container fluid>
        <Row>
          <div className="d-flex justify-content-end mb-3">
            <Button
              variant="primary"
              className="btn-16 add-btn"
              size="lg"
              onClick={handleShow}
            >
              <FiPlus /> Add
            </Button>
          </div>
          <Card className="border-0 p-4 rounded-card user-section custom-table-height">
            <Stack direction="horizontal" gap={3}>
              <div className="p-2">
                <h3>Affiliate Marketing</h3>
              </div>
              <div className="p-2 ms-auto">
                <div className="position-relative m-2">
                  <Form.Control
                    onChange={(e) => setSearchData(e.target.value)}
                    type="search"
                    placeholder="search.."
                    className="serch-feild"
                  />
                  <FiSearch className="searchicon2" />
                </div>
              </div>
            </Stack>
            <Table responsive variant="" className="border-0 custom-table mt-3">
              <thead className="first-td">
                <tr>
                  <th>S.No</th>
                  <th>Created Date</th>
                  <th>Title</th>
                  <th>Image</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.marketingList?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1 + (page - 1) * limit}</td>
                      <td>{new Date(item?.createdAt).toLocaleDateString()}</td>
                      <td>{item?.title || "N/A"}</td>

                      <td>
                        {item?.image ? (
                          <img
                            src={BASE_URL + "/" + item?.image}
                            alt="user image"
                            className="rounded-circle profile-image"
                          />
                        ) : (
                          <img
                            src={Profile}
                            alt="user image"
                            className="rounded-circle profile-image"
                          />
                        )}
                      </td>

                      <td>{item?.description || "N/A"}</td>
                      <td>
                          <Stack
                            direction="horizontal"
                            className="table-btn"
                            gap={3}
                          >
                            <Button
                              onClick={() => dispatch(deleteAffiliate({affiliateId:item?._id})).then(res=>{
                                console.log(res)
                                if(res?.payload?.success){
                                  toast.success(res?.payload?.message)
                                  setflag(!flag)
                                }else{
                                  toast.error(res?.payload.error)
                                }
                              })}
                              variant="success"
                            >
                              Delete
                            </Button>
                          </Stack>
                        </td>

                    </tr>
                  );
                })}
                {data?.marketingList?.length <= 0 && (
                  <tr>
                    <td></td>
                    <td></td>
                    {/* <td></td> */}
                    <td>No Affilates yet</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <Pagination
              totalStuff={data?.totalCount}
              limit={limit}
              setPage={setPage}
            />
          </Card>
        </Row>
      </Container>

      {/* Notify Model */}
      <Modal show={show} onHide={handleClose} className="notify-model">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className=" border-0">
          <Modal.Title className="text-center mb-4">Create New</Modal.Title>

          <Form className="notify-details">
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  name="title"
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setTitleerr("");
                  }}
                  value={title}
                  type="text"
                  placeholder="Enter here.."
                />
              </Form.Group>
              <span className="text-danger">{titleerr}</span>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>URL</Form.Label>
                <Form.Control
                  name="url"
                  onChange={(e) => {
                    setUrl(e.target.value);
                    setUrlerr("");
                  }}
                  value={url}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  // value={values.title}
                  type="text"
                  placeholder="Enter here.."
                />
              </Form.Group>
              <span className="text-danger">{urlerr}</span>
            </Row>

            <Form.Group className="mb-4" controlId="formGridAddress2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                name="desc"
                onChange={(e) => {
                  setDesc(e.target.value);
                  setDescerr("");
                }}
                value={desc}
                // onChange={handleChange}
                // onBlur={handleBlur}
                // value={values.desc}
                as="textarea"
                placeholder="Enter here.."
                rows={6}
              />
              <span className="text-danger">{descerr}</span>
            </Form.Group>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Image/Video</Form.Label>
                <div className="upload-cover-image">
                  <input
                    name="img"
                    onChange={(e) => {
                      setimg(e.target.files[0]);
                      setprev(URL.createObjectURL(e.target.files[0]));
                      setimgerr("");
                    }}
                    // value={createRoute.coverImage}

                    type="file"
                    className="form-control"
                  />
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="30"
                      viewBox="0 0 27 30"
                      fill="none"
                    >
                      <path
                        d="M5.40625 29.9977C5.01378 29.9223 4.61661 29.8675 4.23119 29.7692C1.85522 29.1592 0.170179 27.1896 0.0503229 24.7996C-0.0178306 23.4379 -0.00372988 22.0692 0.0174212 20.7029C0.031522 19.7432 0.506247 19.1058 1.27944 18.8704C2.65426 18.4523 3.80582 19.2977 3.82932 20.7554C3.84577 21.8956 3.83167 23.038 3.83872 24.1804C3.84107 24.3929 3.85282 24.61 3.89512 24.8202C4.05963 25.6496 4.66361 26.1751 5.52846 26.2505C5.71882 26.2665 5.90918 26.2688 6.09954 26.2711C11.0277 26.2711 15.9536 26.2734 20.8818 26.2688C21.1615 26.2688 21.4458 26.2574 21.7185 26.2003C22.5222 26.0312 23.0651 25.476 23.1097 24.6785C23.1661 23.6572 23.145 22.6313 23.1614 21.6077C23.1685 21.1781 23.1379 20.744 23.2014 20.3213C23.3494 19.3434 24.1132 18.7493 25.112 18.779C26.1531 18.811 26.8958 19.4211 26.9381 20.4698C27.0039 22.1629 27.0814 23.8674 26.7971 25.5514C26.4046 27.8751 24.4023 29.6847 21.9911 29.9406C21.883 29.952 21.7772 29.9794 21.6714 30C16.2497 29.9977 10.828 29.9977 5.40625 29.9977Z"
                        fill="#ACACAC"
                      />
                      <path
                        d="M15.3919 6.52301C15.3919 6.83375 15.3919 7.14449 15.3919 7.45523C15.3896 11.1339 15.3943 14.8125 15.3755 18.4911C15.3731 18.8704 15.3026 19.2748 15.1546 19.6267C14.8443 20.3579 14.0477 20.7372 13.2275 20.6206C12.3438 20.495 11.7328 19.9329 11.6106 19.1195C11.5753 18.8887 11.5636 18.6534 11.5636 18.4203C11.5636 14.5566 11.5636 10.6929 11.5636 6.82918C11.5636 6.71722 11.5636 6.60526 11.5636 6.41105C11.2463 6.70808 10.9831 6.94114 10.7316 7.1879C9.59182 8.30292 8.45906 9.4225 7.31925 10.5352C6.81398 11.0288 6.21469 11.3304 5.48381 11.2344C4.24999 11.0745 3.53085 9.87262 4.00088 8.74161C4.13249 8.4263 4.3487 8.12013 4.59781 7.87793C7.04194 5.48111 9.50016 3.09571 11.9584 0.710308C12.9337 -0.235626 14.0218 -0.237911 14.9971 0.710308C17.4718 3.10713 19.9394 5.50853 22.407 7.9122C22.9875 8.47657 23.2554 9.14375 23.0251 9.9366C22.7079 11.0333 21.4458 11.568 20.3648 11.0516C20.0828 10.9168 19.8219 10.7134 19.5986 10.4964C18.4471 9.38137 17.3096 8.25036 16.1651 7.1285C15.9419 6.90915 15.7068 6.70122 15.4789 6.48645C15.4507 6.49787 15.4201 6.5093 15.3919 6.52301Z"
                        fill="#ACACAC"
                      />
                    </svg>
                  </p>
                  <img className="preview-img" src={prev} />
                </div>
                <span className="text-danger">{imgerr}</span>
              </Form.Group>
            </Col>

            <Form.Group
              className="mb-2 model-btn text-center"
              controlId="formGridAddress2"
            >
              <Button
                onClick={handlesave}
                // disabled={isSubmitting}
                variant="primary"
                size="lg"
              >
                Create
              </Button>
            </Form.Group>

            {/*


                {errors.desc && touched.desc && errors.desc}
               */}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
