import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  changeReportUserStatus,
  closeReport,
  deleteReport,
  getReports,
} from "../actions/adminActions";

const initialState = {
  reports: null,
  totalReports: null,
};

export const reportsSlice = createSlice({
  name: "reportsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReports.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.reports = payload?.data?.reports;
          state.totalReports = payload?.data?.totalReports;
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteReport.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.reports = state.reports?.filter(
            (report) => report?._id != payload.data?._id
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(closeReport.fulfilled, (state, { payload }) => {
        if (payload.success) {
          state.reports = state.reports?.map((report) =>
            report?._id == payload.data?._id ? payload.data : report
          );
          toast.success(payload?.message);
        } else if (!payload.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default reportsSlice.reducer;
