import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./css/style.css";
import "./css/responsive.css";
import "./other/bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ToastContainer />
    <Provider store={store}>
      <App />
    </Provider>
  </>
);
