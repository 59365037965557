
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Verified Users 12%', 'Unverified Users 19%'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 20],
      backgroundColor: [
        '#6DBBFA',
        '#00D89D',
      ],
      borderColor: [
        '#6DBBFA',
        '#00D89D ',
      ],
      borderWidth: 1,
    },
  ],
};

export default function UserChart() {
  return <Doughnut className='gender-chart' data={data} />;
}