import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Modal,
  Badge,
  DropdownButton,
  Col,
  Stack,
  Dropdown,
} from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  acceptRejectAffiliate,
  changeSupportStatus,
  getAffilatesDetails,
  getAffiliateRequest,
  getSupports,
  submitAffiliateForm,
} from "../redux/actions/adminActions";
import Pagination from "../components/elements/Pagination";
import { toast } from "react-toastify";
import { CiGlass } from "react-icons/ci";

export default function ManageAffilates() {
  const [Show, setShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [id, setId] = useState("");
  const [flag, setflag] = useState(true);
  const data = useSelector((state) => state.manageAffiliatesdata.list);
  const details = useSelector((state) => state.manageAffiliatesdata.detail);

  console.log(details, "details");
  console.log(id);
  console.log(data, "data");

  // const [show, setShow, setLgShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const limit = 5;
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState(1);
  const [rewardPerRefer, setrewardPerRefer] = useState("");
  const [rewardPerRefererr, setrewardPerRefererr] = useState("");
  const [thresholdLimiterr, setthresholdLimiterr] = useState("");
  const [thresholdLimit, setthresholdLimit] = useState("");

  useEffect(() => {
    dispatch(getAffiliateRequest({ page, limit, search: searchData }));
  }, [page, searchData, flag]);

  useEffect(() => {
    dispatch(getAffilatesDetails(id));
  }, [id]);

  // const handleStatusChange = (id, status) => {
  //   dispatch(changeSupportStatus({ id, status }));
  // };

  return (
    <>
      <Container fluid>
        <Row>
          <Card className="border-0 p-4 rounded-card user-section custom-table-height">
            <Stack direction="horizontal" gap={3}>
              <div className="p-2">
                <h3>Manage Affiliates</h3>
              </div>
              <div className="p-2 ms-auto">
                <div className="position-relative m-2">
                  <Form.Control
                    onChange={(e) => setSearchData(e.target.value)}
                    type="search"
                    placeholder="Search..."
                    className="serch-feild"
                  />
                  <FiSearch className="searchicon2" />
                </div>
              </div>
            </Stack>
            <Table responsive variant="" className="border-0 custom-table mt-3">
              <thead className="first-td">
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Address</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data?.requestList?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1 + (page - 1) * limit}</td>
                      <td
                        onClick={() => {
                          setId(item?._id);
                          setShow(true);
                        }}
                        className="cursor-pointer"
                      >
                        {item?.firstName} {item?.lastName}
                      </td>
                      <td>{new Date(item?.createdAt).toLocaleDateString()}</td>
                      <td>
                        {item?.addressLine1} ,  <br />
                        {item?.addressLine2}
                      </td>
                      <td>
                        <Badge bg="primary">
                          {item?.status == 0
                            ? "Pending"
                            : item?.status == "1"
                            ? "Accepted"
                            : "Rejected"}
                        </Badge>
                      </td>
                    </tr>
                  );
                })}
                {data?.requestList?.length <= 0 && (
                  <tr>
                    <td></td>
                    <td></td>
                    {/* <td></td> */}
                    <td>No Affiliates yet</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <Pagination
              totalStuff={data?.totalCount}
              limit={limit}
              setPage={setPage}
            />
          </Card>
        </Row>
      </Container>

      {/* Notify Model */}
      <Modal show={Show} onHide={() => setShow(false)} className="notify-model">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className=" border-0">
          <Modal.Title className="text-center mb-4">
            Affiliate Request Form
          </Modal.Title>

          <Form className="notify-details">
            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    value={details ? details?.firstName : "N/A"}
                    type="text"
                    placeholder="Enter here.."
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    value={details ? details?.lastName : "N/A"}
                    type="text"
                    placeholder="Enter here.."
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Address Line 1</Form.Label>
                <Form.Control
                  value={details ? details?.addressLine1 : "N/A"}
                  type="text"
                  placeholder="Enter here.."
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Address Line 2</Form.Label>
                <Form.Control
                  value={details ? details?.addressLine2 : "N/A"}
                  type="text"
                  placeholder="Enter here.."
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    value={details ? details?.city : "N/A"}
                    type="text"
                    placeholder="Enter here.."
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>State/Province</Form.Label>
                  <Form.Control
                    value={details ? details?.state : "N/A"}
                    type="text"
                    placeholder="Enter here.."
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Postal/Zip Code</Form.Label>
                <Form.Control
                  value={details ? details?.zip : "N/A"}
                  type="text"
                  placeholder="Enter here.."
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Website URL</Form.Label>
                <Form.Control
                  value={details ? details?.url : "N/A"}
                  type="text"
                  placeholder="Enter here.."
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  value={
                    new Date(
                      details ? details?.createdAt : null
                    ).toLocaleDateString() || "N/A"
                  }
                  type="text"
                  placeholder="Enter here.."
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-4" controlId="formGridAddress2">
              <Form.Label>How will you promote?</Form.Label>
              <Form.Control
                value={details ? details?.promote : "N/A"}
                as="textarea"
                placeholder="Enter here.."
                rows={6}
              />
            </Form.Group>

            <Form.Group
              className="mb-2 model-btn text-center"
              controlId="formGridAddress2"
            >
              <Button
                type="button"
                // disabled={isSubmitting}
                onClick={() =>
                  dispatch(
                    acceptRejectAffiliate({ requestId: id, type: 2 })
                  ).then((res) => {
                    if (res?.payload?.success) {
                      toast.success(res?.payload?.message);
                      setShow(false);
                      setflag(!flag);
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  })
                }
                variant="primary"
                size="lg"
                className="me-2 btn-grey"
              >
                Reject
              </Button>
              <Button
                type="button"
                // disabled={isSubmitting}

                variant="primary"
                size="lg"
                onClick={() => setLgShow(true)}
              >
                Accept
              </Button>
            </Form.Group>

            {/*
                {errors.desc && touched.desc && errors.desc}
               */}
          </Form>
        </Modal.Body>
      </Modal>

      {/* Second modal*/}
      <Modal
        show={lgShow}
        onHide={() => setLgShow(false)}
        className="notify-model"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className=" border-0">
          <Modal.Title className="text-center mb-4">
            Affiliate Request Form
          </Modal.Title>

          <Form className="notify-details">
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>O<sup>2</sup> Reward per referral</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setrewardPerRefer(e.target.value);
                    setrewardPerRefererr("")
                  }}
                  name="rewardPerRefer"
                  value={rewardPerRefer}
                  type="text"
                  placeholder="Lorem Ipsum is simply dummy text."
                />
                <Form.Label className="text-danger">{rewardPerRefererr}</Form.Label>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Set Threshold Limit</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setthresholdLimit(e.target.value);
                    setthresholdLimiterr("")
                  }}
                  name="thresholdLimit"
                  value={thresholdLimit}
                  type="text"
                  placeholder="Lorem Ipsum is simply dummy text."
                />
                 <Form.Label className="text-danger">{thresholdLimiterr}</Form.Label>
              </Form.Group>
            </Row>

            <Form.Group
              className="mb-2 model-btn text-center"
              controlId="formGridAddress2"
            >
              <Button
                type="button"
                onClick={() => {
                  let isvalid = true;
                  if (!rewardPerRefer) {
                    setrewardPerRefererr("Required*");
                    isvalid = false;
                  }
                  if (!thresholdLimit) {
                    setthresholdLimiterr("Required*");
                    isvalid = false;
                  }
                  if (isvalid) {
                    dispatch(
                      submitAffiliateForm({
                        rewardPerRefer: rewardPerRefer,
                        thresholdLimit: thresholdLimit,
                        status: 1,
                        requestId: id,
                      })
                    ).then((res) => {
                      console.log(res);
                      if (res?.payload?.success) {
                        toast.success(res?.payload?.message);
                        setLgShow(false);
                        setShow(false);
                        setflag(!flag);
                        setrewardPerRefer("");
                        setthresholdLimit("");
                      } else {
                        toast.error(res?.payload?.message);
                      }
                    });
                  }
                }}
                variant="primary"
                size="lg"
              >
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
