import { createContext } from "react";
const { io } = require("socket.io-client");

export const SocketContext = createContext();

const ChatContext = ({ children }) => {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const socket = io(BASE_URL);
  return (
    <SocketContext.Provider value={socket}> {children} </SocketContext.Provider>
  );
};

export default ChatContext;
