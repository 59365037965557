import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import Profile from "../../images/profile.png";
import Figure from "react-bootstrap/Figure";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { FiMapPin, FiCheck, FiUser, FiArrowLeft } from "react-icons/fi";
import Button from "react-bootstrap/Button";
import "react-circular-progressbar/dist/styles.css";
import Photo from "../../images/photo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import iconlove from "../../images/icon_love.png";
import thumbimg from "../../images/thumb.png";
import thumbimg2 from "../../images/thumb2.png";
import ListGroup from "react-bootstrap/ListGroup";
import videoposter from "../../images/videoposter.png";
import playvideo from "../../images/play-video.png";
import { useDispatch, useSelector } from "react-redux";
import {
  deactivateUserAccount,
  deleteUserAccount,
  getUserDetails,
} from "../../redux/actions/adminActions";
import { toast } from "react-toastify";
import ViewImage from "../elements/ViewImage";
import GoogleMap from "../elements/GoogleMap";

export default function UserDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [delShow, setDel] = useState(false);
  const [deacShow, setDeactivateShow] = useState(false);
  const [deacStatus, setDeacStatus] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const userDetails = useSelector((state) => state.userMgmtSlice.userDetails);

  useEffect(() => {
    dispatch(getUserDetails(id));
  }, []);

  useEffect(() => {
    if (userDetails) {
      setDeacStatus(userDetails?.deactivated == 0 ? false : true);
    }
  }, [userDetails]);

  const yesNoObj = { 0: "No", 1: "Yes", 3: "N/A" };

  const openImageViewer = (img) => {
    setCurrentImage(img);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage();
    setIsViewerOpen(false);
  };

  const closeDel = () => setDel(false);
  const closeDeac = () => setDeactivateShow(false);

  const handleDeleteAcc = () => {
    dispatch(deleteUserAccount(id))
      .then((response) => {
        if (response?.payload?.success) {
          closeDel();
          navigate("/admin/users");
          toast.success(response?.payload?.message);
        } else {
          toast.error(response?.payload?.message);
        }
      })
      .catch((error) => console.log(error));
  };
  const handleDeactivateAcc = () => {
    const status = deacStatus == false ? 1 : 0;

    dispatch(deactivateUserAccount({ status, user_id: id }))
      .then((response) => {
        if (response?.payload?.success) {
          closeDeac();
          setDeacStatus((prev) => !prev);
          toast.success(response?.payload?.message);
        } else {
          closeDeac();
          toast.error(response?.payload?.message);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {isViewerOpen && (
        <ViewImage image={[currentImage]} closeImageViewer={closeImageViewer} />
      )}

      <Container fluid>
        <Row>
          <h5
            onClick={() => navigate(-1)}
            className="text-primary-clr pb-4 cursor-pointer"
          >
            <svg
              width="23"
              height="14"
              viewBox="0 0 23 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.3638 6.83333C22.3638 7.28439 22.0206 7.65717 21.5753 7.71616L21.4516 7.7243L11.4789 7.72415L11.4781 12.7743C11.4781 13.4343 10.7774 13.8508 10.1885 13.5858L10.0796 13.5281L0.42605 7.58714C-0.14184 7.23765 -0.14184 6.42901 0.42605 6.07953L10.0796 0.13859C10.6871 -0.235242 11.4781 0.191142 11.4781 0.892396L11.4789 5.94127L21.4516 5.94237C21.9554 5.94237 22.3638 6.34127 22.3638 6.83333ZM9.65468 11.1583L9.65388 6.85105C9.65376 6.84516 9.6537 6.83925 9.6537 6.83333L9.65501 6.81842L9.65468 2.50646L2.62461 6.83298L9.65468 11.1583Z"
                fill="#FF148C"
              />
            </svg>{" "}
            Back
          </h5>

          <Row>
            <Col lg={8}>
              <Card className="border-0 py-3 px-4 rounded-card mb-4">
                <Row>
                  <h5 className="mb-3">Happenings</h5>
                  <div className="stoies-contin">
                    {userDetails?.stories?.length > 0 ? (
                      userDetails?.stories?.map((story) => {
                        const media = BASE_URL + "/" + story?.story;
                        const fileExtension = story?.story
                          ?.split(".")
                          .pop()
                          .toLowerCase();

                        if (
                          ["jpeg", "jfif", "jpg", "png"].includes(fileExtension)
                        ) {
                          return (
                            <div className="stoies-list">
                              <img
                                src={media}
                                className="img-thumbnail border-0"
                                onClick={() => openImageViewer(media)}
                              />
                            </div>
                          );
                        } else if (["mp4", "mov"].includes(fileExtension)) {
                          return (
                            <div className="stoies-list">
                              <video src={media} controls />
                            </div>
                          );
                        }
                      })
                    ) : (
                      <img src={Profile} />
                    )}
                  </div>
                </Row>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="border-0 py-3 px-4 rounded-card deactivate-sec">
                <div className="delete-account-info flex-wrap">
                  <button onClick={() => setDel(true)} type="button">
                    Delete Account
                  </button>

                  <div className="delete-switch d-flex align-items-center ">
                    <label>Deactivate Account</label>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      checked={deacStatus}
                      onChange={() => setDeactivateShow(true)}
                    />
                  </div>
                </div>
              </Card>
            </Col>

            <Col lg={8}>
              <Card className="border-0 py-4 px-4  rounded-card">
                <Row>
                  <Col lg={8}>
                    <Figure className="d-flex user-info">
                      <Figure.Image
                        // alt="171x180"
                        onClick={() =>
                          openImageViewer(
                            BASE_URL + "/" + userDetails?.profile_image
                          )
                        }
                        alt="profile_image"
                        src={BASE_URL + "/" + userDetails?.profile_image}
                        className="border-0 profile-img"
                        roundedCircle
                      />
                      <Figure.Caption className="px-4 text-dark">
                        <div>
                          <h3 className="fw-bold">
                            {userDetails?.full_name.trim()},{" "}
                            <span className="fw-normal">
                              {userDetails?.age}
                            </span>{" "}
                            <sup>
                              <Badge className="rounded-circle" bg="success">
                                {userDetails?.admin_verfied == 1 && <FiCheck />}
                              </Badge>
                            </sup>
                          </h3>

                          <h6>
                            <span className="dslpnonmob">
                              {userDetails?.country_code +
                                "-" +
                                userDetails?.phone_number}
                            </span>
                          </h6>
                          <h6>
                            <span className="dslpnonmob">
                              {userDetails?.email}
                            </span>
                          </h6>
                          <h6>
                            <span className="dslpnonmob">
                              Gender -{" "}
                              {userDetails?.gender == 0
                                ? "Female"
                                : userDetails?.gender == 1
                                ? "Male"
                                : userDetails?.gender == 2 && "Others"}
                            </span>
                          </h6>
                          <h6>
                            <span className="dslpnonmob">
                              Occupation -{" "}
                              {userDetails?.occupation
                                ? userDetails?.occupation?.id?.name
                                : "N/A"}
                            </span>
                          </h6>
                        </div>
                        <div>
                          <Button className="mt-3" variant="info" size="md">
                            {userDetails?.banned == 0
                              ? "ACTIVE"
                              : userDetails?.banned == 1
                              ? "TEMPORARILY BANNED"
                              : userDetails?.banned == 2 && "PERMANENT BANNED"}
                          </Button>{" "}
                        </div>
                      </Figure.Caption>
                    </Figure>
                  </Col>
                  <Col lg={4} className="d-flex justify-content-end">
                    <div className="profile-complete">
                      <h6>Profile</h6>
                      <h4>Complete</h4>
                      <div className="complete-percentage">50%</div>
                    </div>
                  </Col>
                </Row>

                <hr style={{ color: "rgb(171 171 171)" }} />

                <Row>
                  <Col lg={9}>
                    <div className="bio-sec">
                      <h3>Bio</h3>
                      <p>{userDetails?.bio ? userDetails?.bio : "N/A"}</p>
                    </div>
                  </Col>
                  <Col xl="3" lg="12" className="">
                    <h6 className="mb-3">Looking for</h6>
                    <Row>
                      {/* {userDetails?.lookingFor?.length > 0 &&
                      userDetails?.lookingFor?.map((lf) => {
                        return ( */}
                      <Col xxl="12" xl="12" lg="12" className="mb-4">
                        <div className="shadow-user-info text-center">
                          <img
                            src={
                              BASE_URL + "/" + userDetails?.lookingFor[0]?.image
                            }
                          />
                          <h6>{userDetails?.lookingFor[0]?.name}</h6>
                        </div>
                      </Col>
                      {/* );
                      })} */}
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col lg={4} className="mt-991-4">
              <Card className="border-0 py-4 px-4 rounded-card intro-vid ">
                <h5>Intro Video</h5>
                <div>
                  {userDetails?.videos?.length > 0 ? (
                    <video
                      src={BASE_URL + "/" + userDetails?.videos[0]}
                      poster={playvideo}
                      width="100%"
                      controls
                    />
                  ) : (
                    <video poster={videoposter} width="100%" />
                  )}
                </div>
              </Card>
            </Col>

            <Col lg={8}>
              <Card className="border-0 py-4 px-4 mt-4 rounded-card  justify-content-between">
                <div className="intrest-height-sec">
                  <Row>
                    <Col lg="6" className="">
                      <div>
                        <h6 className="mb-2">Interests</h6>
                      </div>
                      <ListGroup>
                        {userDetails?.into?.length > 0 &&
                          userDetails?.into?.map((into) => (
                            <ListGroup.Item>
                              <img
                                className="into-img"
                                src={BASE_URL + into?.image}
                              />{" "}
                              {into?.name}
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    </Col>

                    <Col lg="6">
                      <div>
                        <h6 className="mb-2 mt-991-4">
                          Can Speak these Languages
                        </h6>
                        <ListGroup>
                          {userDetails?.languages?.length > 0 ? (
                            userDetails?.languages?.map((lang) => (
                              <ListGroup.Item>{lang}</ListGroup.Item>
                            ))
                          ) : (
                            <ListGroup.Item>{"N/A"}</ListGroup.Item>
                          )}
                        </ListGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xl="12" lg="12" className="">
                      <h6 className="mb-3 mt-991-4">Location</h6>
                      <p className="google-map-address">
                        {userDetails?.address}
                      </p>
                      <div className="user-map">
                        <GoogleMap
                          long={userDetails?.location?.coordinates[0]}
                          lat={userDetails?.location?.coordinates[1]}
                        />
                        {/* <img src={require("../../images/map.png")} /> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="border-0 py-4 px-4 rounded-card mt-4 ">
                <div className="pics-sec ">
                  <h5>Featured Pictures</h5>

                  <Stack direction="horizontal" gap={0} className="">
                    {userDetails?.posts?.map((post) => {
                      const image = BASE_URL + "/" + post?.image;
                      const fileExtension = post?.image
                        ?.split(".")
                        .pop()
                        .toLowerCase();

                      if (
                        ["jpeg", "jfif", "jpg", "png"].includes(fileExtension)
                      ) {
                        return (
                          <img
                            src={image}
                            className="img-thumbnail border-0"
                            onClick={() => openImageViewer(image)}
                          />
                        );
                      } else if (["mp4", "mov"].includes(fileExtension)) {
                        return <video src={image} controls />;
                      }
                    })}
                  </Stack>
                </div>
              </Card>
            </Col>

            <Col lg="8">
              <Card className="border-0 py-4 px-4 mt-4 rounded-card ">
                <Row className="bottom-card-height">
                  <Col lg="4" className="">
                    <div>
                      <h6 className="mb-2">Sexual orientation</h6>
                      <ListGroup>
                        {userDetails?.sexualOrientation?.length > 0 &&
                          userDetails?.sexualOrientation?.map(
                            (sexualOrientation) => (
                              <ListGroup.Item>
                                {sexualOrientation?.name}
                              </ListGroup.Item>
                            )
                          )}
                      </ListGroup>
                    </div>
                  </Col>
                  <Col lg="4" className="">
                    <div>
                      <h6 className="mb-2 mt-991-4">Love Languages</h6>
                      <ListGroup>
                        <ListGroup.Item>
                          {userDetails?.loveLanguage?.name
                            ? userDetails?.loveLanguage?.name
                            : "N/A"}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>
                  <Col lg="4" className="">
                    <div>
                      <h6 className="mb-2 mt-991-4">Zodiac</h6>
                      <ListGroup>
                        <ListGroup.Item>
                          {userDetails?.zodiac
                            ? userDetails?.zodiac?.name
                            : "N/A"}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>

                  <Col lg="4" className="">
                    <div>
                      <h6 className="mb-2 mt-4">Height</h6>
                      <ListGroup>
                        <ListGroup.Item>
                          {userDetails?.height ? userDetails?.height : "N/A"}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>
                  <Col lg="4" className="">
                    <div>
                      <h6 className="mb-2 mt-4">Alcohol Consumption</h6>
                      <ListGroup>
                        <ListGroup.Item>
                          {yesNoObj[userDetails?.alcohol]}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>

                  <Col lg="4" className="">
                    <div>
                      <h6 className="mb-2 mt-4">Cannabis Consumption</h6>
                      <ListGroup>
                        <ListGroup.Item>
                          {yesNoObj[userDetails?.cannabis]}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>
                  <Col lg="4" className="">
                    <div>
                      <h6 className="mb-2 mt-4">Smoking Consumption</h6>
                      <ListGroup>
                        <ListGroup.Item>
                          {yesNoObj[userDetails?.smoking]}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>

                  <Col lg="4" className="">
                    <div>
                      <h6 className="mb-2 mt-4">Religion</h6>
                      <ListGroup>
                        <ListGroup.Item>
                          {userDetails?.religion
                            ? userDetails?.religion?.id?.name
                            : "N/A"}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>
                  <Col lg="4" className="">
                    <div>
                      <h6 className="mb-2 mt-4">Kids</h6>
                      <ListGroup>
                        <ListGroup.Item>
                          {yesNoObj[userDetails?.haveKids]}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>

                  <Col lg="4" className="">
                    <div>
                      <h6 className="mb-2 mt-4">Education</h6>
                      <ListGroup>
                        <ListGroup.Item>Graduate</ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>
                  <Col lg="4" className="">
                    <div>
                      <h6 className="mb-2 mt-4">Physical Exercise</h6>
                      <ListGroup>
                        <ListGroup.Item>
                          {userDetails?.exercise
                            ? userDetails?.exercise?.id?.name
                            : "N/A"}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col lg="4" className="photo-gallery ">
              <Card className="border-0 py-4 px-4 rounded-card mt-4">
                <div className="dash-social-links bottom-card-social-height">
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Facebook</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="John-Smith"
                        value={
                          userDetails?.facebook
                            ? userDetails?.facebook.toLowerCase()
                            : "N/A"
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Linkedin</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="John-Smith"
                        value={
                          userDetails?.linkedin
                            ? userDetails?.linkedin.toLowerCase()
                            : "N/A"
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Spotify</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="John-Smith"
                        value={
                          userDetails?.spotify
                            ? userDetails?.spotify.toLowerCase()
                            : "N/A"
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Snapchat</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="@JohnSmith"
                        value={
                          userDetails?.snapchat
                            ? userDetails?.snapchat.toLowerCase()
                            : "N/A"
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Tiktok</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="J@JohnSmith"
                        value={
                          userDetails?.tiktok
                            ? userDetails?.tiktok.toLowerCase()
                            : "N/A"
                        }
                      />
                    </Form.Group>
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
        </Row>
      </Container>

      {/* Delete Account Modal */}

      <Modal show={delShow} onHide={closeDel} className="notify-model">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className=" border-0">
          <Modal.Title className="text-center mb-2">Delete Account</Modal.Title>
          <p>Are you sure you want to delete this account?</p>
          <div className="d-flex justify-content-between">
            <Button variant="primary" size="lg" onClick={handleDeleteAcc}>
              Yes
            </Button>
            <Button
              variant="primary"
              className="no-delete"
              size="lg"
              onClick={closeDel}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Deactivate Account Modal */}

      <Modal
        show={deacShow}
        onHide={closeDeac}
        className="notify-model delete-popup"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className=" border-0">
          <Modal.Title className="text-center mb-2">
            Deactivate Account
          </Modal.Title>
          <p>Are you sure you want to deactivate this account?</p>
          <div className="d-flex justify-content-between">
            <Button variant="primary" size="lg" onClick={handleDeactivateAcc}>
              Yes
            </Button>
            <Button
              variant="primary"
              className="no-delete"
              size="lg"
              onClick={closeDeac}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
