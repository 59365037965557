import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Stack,
  Modal,
  Col,
} from "react-bootstrap";
import { FiSearch, FiPlus } from "react-icons/fi";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotification,
  deleteNotification,
  getNotifications,
} from "../redux/actions/adminActions";
import { useEffect } from "react";

export default function Notification() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const notifications = useSelector(
    (state) => state.notificationSlice.notifications
  );

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const handleDelete = (id) => {
    dispatch(deleteNotification(id));
  };

  const toObj = {
    0: "Female",
    1: "Male",
    2: "Others",
    3: "Everyone",
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="d-flex justify-content-end mb-3">
            <Button
              variant="primary"
              className="btn-16 add-btn"
              size="lg"
              onClick={handleShow}
            >
              <FiPlus /> Add
            </Button>
          </div>
          <Card className="border-0 p-4 rounded-card user-section custom-table-height">
            <Stack direction="horizontal" gap={3}>
              <div className="p-2">
                <h3>Notification</h3>
              </div>
              <div className="p-2 ms-auto">
                <div className="position-relative m-2">
                  <Form.Control
                    type="search"
                    placeholder="search.."
                    className="serch-feild"
                  />
                  <FiSearch className="searchicon2" />
                </div>
              </div>
            </Stack>
            <Table responsive variant="" className="border-0 custom-table mt-3">
              <thead className="first-td">
                <tr>
                  <th>S.No</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>To</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {notifications?.length > 0 ? (
                  notifications?.map((not, i) => {
                    return (
                      <tr key={not?._id}>
                        <td>{i + 1}</td>
                        <td>{not?.title}</td>
                        <td>{not?.desc}</td>
                        <td>{toObj[not?.to]}</td>
                        <td>
                          <Stack
                            direction="horizontal"
                            className="table-btn"
                            gap={3}
                          >
                            <Button
                              onClick={() => handleDelete(not?._id)}
                              variant="success"
                            >
                              Delete
                            </Button>
                          </Stack>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>No Notifications yet</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card>
        </Row>
      </Container>

      {/* Notify Model */}
      <Modal show={show} onHide={handleClose} className="notify-model">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className=" border-0">
          <Modal.Title className="text-center mb-4">
            Add Notification
          </Modal.Title>

          <Formik
            initialValues={{ title: "", desc: "", to: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.title) {
                errors.title = "Required*";
              }
              if (!values.desc) {
                errors.desc = "Required*";
              }
              if (!values.to) {
                errors.to = "Required*";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(addNotification(values));
              handleClose();
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit} className="notify-details">
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Select
                      name="to"
                      onChange={handleChange}
                      aria-label="Default select example"
                    >
                      <option hidden={true}>Select User</option>
                      <option value={0}>Female</option>
                      <option value={1}>Male </option>
                      <option value={2}>Others</option>
                      <option value={3}>Everyone</option>
                    </Form.Select>
                  </Form.Group>
                  <span className="formik-errors">
                    {errors.to && touched.to && errors.to}
                  </span>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      type="text"
                      placeholder="Title"
                    />
                  </Form.Group>
                  <span className="formik-errors">
                    {errors.title && touched.title && errors.title}
                  </span>
                </Row>

                <Form.Group className="mb-4" controlId="formGridAddress2">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="desc"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.desc}
                    as="textarea"
                    placeholder="Leave a comment here"
                    rows={6}
                  />
                  <span className="formik-errors">
                    {errors.desc && touched.desc && errors.desc}
                  </span>
                </Form.Group>

                <Form.Group
                  className="mb-2 model-btn text-center"
                  controlId="formGridAddress2"
                >
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    size="lg"
                  >
                    Notify
                  </Button>
                </Form.Group>

                {/*


                {errors.desc && touched.desc && errors.desc}
               */}
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
