// import React, { useEffect } from "react";
// import { Card, Container, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
// import Table from "react-bootstrap/Table";
// import { FiPlus, FiSearch } from "react-icons/fi";
// import Stack from "react-bootstrap/Stack";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   adminVerifyUser,
//   getVerificationUsers,
// } from "../redux/actions/adminActions";

// export default function Gift() {
//   return (
//     <>
//       <Container fluid>
//         <div className="d-flex justify-content-end mb-3">
//           <Button variant="primary" className="btn-16" size="lg">
//             <FiPlus /> Add
//           </Button>
//         </div>
//         <Row>
//           <Card className="border-0 p-4 rounded-card user-section custom-table-height">
//             <Stack direction="horizontal" gap={3}>
//               <div className="p-2">
//                 <h3>Gift</h3>
//               </div>
//               <div className="p-2 ms-auto">
//                 <div className="position-relative m-2">
//                   <Form.Control
//                     type="search"
//                     placeholder="search.."
//                     className="serch-feild"
//                   />
//                   <FiSearch className="searchicon2" />
//                 </div>
//               </div>
//             </Stack>
//             <Table responsive variant="" className="border-0 custom-table mt-3">
//               <thead className="first-td">
//                 <tr>
//                   <th>S.No</th>
//                   <th>Image</th>
//                   <th>Gift Title</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td>1.</td>
//                   <td>
//                     <img
//                       src={require("../images/o2.svg").default}
//                       alt="user image"
//                       className="rounded verify-pics"
//                     />
//                   </td>
//                   <td>Send 1 Credit</td>
//                   <td>
//                     <button className="delete-gift">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="15"
//                         height="16"
//                         viewBox="0 0 15 16"
//                         fill="none"
//                       >
//                         <path
//                           fill-rule="evenodd"
//                           clip-rule="evenodd"
//                           d="M7.39757 16C6.31355 16 5.25674 15.988 4.21112 15.9664C2.8735 15.94 1.94789 15.0727 1.79668 13.7031C1.54468 11.4311 1.11347 6.07581 1.10947 6.02221C1.08227 5.6918 1.32868 5.4022 1.65908 5.3758C1.98469 5.367 2.27909 5.5958 2.30549 5.9254C2.30949 5.97981 2.7399 11.3167 2.9895 13.5711C3.0751 14.3495 3.49511 14.7511 4.23592 14.7663C6.23595 14.8087 8.27678 14.8111 10.4768 14.7711C11.264 14.7559 11.6896 14.3623 11.7776 13.5655C12.0256 11.3303 12.4576 5.97981 12.4624 5.9254C12.4888 5.5958 12.7808 5.3654 13.1081 5.3758C13.4385 5.403 13.6849 5.6918 13.6585 6.02221C13.6537 6.07661 13.2201 11.4455 12.9704 13.6975C12.8152 15.0951 11.892 15.9456 10.4984 15.9712C9.432 15.9896 8.40318 16 7.39757 16Z"
//                           fill="#FF5353"
//                         />
//                         <path
//                           fill-rule="evenodd"
//                           clip-rule="evenodd"
//                           d="M14.1666 3.99131H0.600009C0.268804 3.99131 0 3.7225 0 3.3913C0 3.06009 0.268804 2.79129 0.600009 2.79129H14.1666C14.4978 2.79129 14.7666 3.06009 14.7666 3.3913C14.7666 3.7225 14.4978 3.99131 14.1666 3.99131Z"
//                           fill="#FF5353"
//                         />
//                         <path
//                           fill-rule="evenodd"
//                           clip-rule="evenodd"
//                           d="M11.5528 3.99126C10.6424 3.99126 9.85197 3.34245 9.67276 2.44964L9.47836 1.47682C9.43756 1.32882 9.26876 1.20002 9.07675 1.20002H5.6903C5.4983 1.20002 5.3295 1.32882 5.2807 1.51362L5.09429 2.44964C4.91589 3.34245 4.12468 3.99126 3.21427 3.99126C2.88306 3.99126 2.61426 3.72246 2.61426 3.39125C2.61426 3.06005 2.88306 2.79124 3.21427 2.79124C3.55507 2.79124 3.85108 2.54804 3.91828 2.21363L4.11268 1.24082C4.31028 0.495207 4.95589 0 5.6903 0H9.07675C9.81117 0 10.4568 0.495207 10.6464 1.20482L10.8496 2.21363C10.916 2.54804 11.212 2.79124 11.5528 2.79124C11.884 2.79124 12.1528 3.06005 12.1528 3.39125C12.1528 3.72246 11.884 3.99126 11.5528 3.99126Z"
//                           fill="#FF5353"
//                         />
//                       </svg>
//                     </button>
//                   </td>
//                 </tr>
//               </tbody>
//             </Table>
//           </Card>
//         </Row>
//       </Container>
//     </>
//   );
// }
