import { Navigate } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Login from "../pages/Login";
import User from "../pages/User";
import Dashboard from "../pages/Dashboard";
import Layout from "../components/Layout/Layout";
import UserVerification from "../pages/UserVerification";
import Report from "../pages/Report";
import Notification from "../pages/Notification";
import Analytics from "../pages/Analytics";
import Support from "../pages/Support";
import SupportDetail from "../components/support/SupportDetail";
import ChangePassword from "../pages/ChangePassword";
import UserDetail from "../components/user/UserDetail";
import Wallet from "../pages/Wallet";
import AddGift from "../pages/AddGift";
import ChatContext from "../context/ChatContext";
import Gift from "../pages/Gift";
import ManageAffilates from "../pages/ManageAffilates";
import AffiliateMarketing from "../pages/AffiliateMarketing";

const PageRoutes = () => {
  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<Login />} />
    //     <Route path="admin/*" element={<Navigate to="/admin/dashboard" />} />
    //     <Route path="/admin" element={<PrivateRoute />}>
    //       <Route path="/admin" element={<Layout />}>
    //         <Route path="users" element={<User />} />
    //         <Route path="dashboard" element={<Dashboard />} />
    //         <Route path="userdetail" element={<UserDetail />} />
    //         <Route path="general" element={<General />} />
    //       </Route>
    //     </Route>
    //   </Routes>
    // </BrowserRouter>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="admin/*" element={<Navigate to="/admin/dashboard" />} />
        <Route path="/admin" element={<PrivateRoute />}>
          <Route path="/admin" element={<Layout />}>
            <Route path="users" element={<User />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="userdetail/:id" element={<UserDetail />} />
            <Route path="user-verification" element={<UserVerification />} />
            <Route path="report" element={<Report />} />
            <Route path="notification" element={<Notification />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="support" element={<Support />} />
            <Route path="manage-affilates" element={<ManageAffilates />} />
            <Route path="affiliate-marketing" element={<AffiliateMarketing />} />

            <Route
              path="support-detail/:id"
              element={
                <ChatContext>
                  <SupportDetail />
                </ChatContext>
              }
            />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="Wallet" element={<Wallet />} />
            <Route path="Add-gift" element={<AddGift />} />
            <Route path="gift" element={<Gift />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default PageRoutes;
