import React from "react";
import { modalData } from "./modalData";
import { Button, Modal } from "react-bootstrap";
import { adminVerifyUser, closeReport, deleteReport } from "../../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const SimpleModal = ({ type, show, setShow, data }) => {
  const { id, status } = data;
  const { title, body } = modalData[type];
  const dispatch = useDispatch();
  const funcs = {
    closeReport: () => {
      dispatch(closeReport({ id, status }));
    },
    deleteReport: () => {
      dispatch(deleteReport(id));
    },
    verifyUser: () => {
      dispatch(adminVerifyUser({ status, id }));
    },
    dontVerifyUser: () => {
      dispatch(adminVerifyUser({ status, id }));
    },
  };

  const handleClose = () => setShow(false);

  const handleSubmit = () => {
    funcs[type]();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} className="notify-model">
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body className=" border-0">
        <Modal.Title className="text-center mb-2">{title}</Modal.Title>
        <p>{body}</p>
        <div className="d-flex justify-content-between">
          <Button variant="primary" size="lg" onClick={handleSubmit}>
            Yes
          </Button>
          <Button
            className="no-delete"
            variant="primary"
            size="lg"
            onClick={handleClose}
          >
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SimpleModal;
