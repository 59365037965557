import ImageViewer from "react-simple-image-viewer";
const ViewImage = ({ image, closeImageViewer }) => {
  //   const closeImageViewer = () => {
  //     setCurrentImage();
  //     setIsViewerOpen(false);
  //   };
  return (
    <div>
      <ImageViewer
        src={image}
        currentIndex={0}
        onClose={closeImageViewer}
        disableScroll={false}
        backgroundStyle={{
          backgroundColor: "rgba(0,0,0,0.9)",
        }}
        closeOnClickOutside={true}
      />
    </div>
  );
};

export default ViewImage;
