import React from "react";
import { Container, Row } from "react-bootstrap";

const Analytics = () => {
  return (
    <Container fluid>
      <div className="main">UNDER DEVELOPMENT</div>
      <Row></Row>
    </Container>
  );
};

export default Analytics;
