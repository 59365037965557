import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Stack,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  changeReportUserStatus,
  deleteReport,
  getReports,
} from "../redux/actions/adminActions";
import { toast } from "react-toastify";
import Pagination from "../components/elements/Pagination";
import SimpleModal from "../components/modal/SimpleModal";

export default function Report() {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reportsSlice.reports);
  const totalReports = useSelector((state) => state.reportsSlice.totalReports);
  const limit = 5;
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState(1);
  const [id, setId] = useState();
  const [status, setStatus] = useState();
  const [type, setType] = useState();

  useEffect(() => {
    dispatch(getReports({ page, limit, search: searchData }));
  }, [page, searchData]);

  const handleStatusChange = (id, status) => {
    dispatch(changeReportUserStatus({ id, status }))
      .then((response) => {
        console.log(response);
        if (response?.payload?.success) {
          dispatch(getReports({ page, limit, search: searchData }));
          toast.success(response?.payload.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClick = (id, status) => {
    setId(id);
    setType("closeReport");
    setStatus(status == 0 ? 1 : status == 1 && 0);
    setShow(true);
  };

  const handleDelete = (id) => {
    setId(id);
    setStatus("deleteReport");
    setType("deleteReport");
    setShow(true);
  };

  return (
    <>
      {id && status != undefined && (
        <SimpleModal
          type={type}
          show={show}
          setShow={setShow}
          data={{ id, status }}
        />
      )}

      <Container fluid>
        <Row>
          <Card className="border-0 p-4 rounded-card user-section custom-table-height">
            <Stack direction="horizontal" gap={3}>
              <div className="p-2">
                <h3>Report</h3>
              </div>
              <div className="p-2 ms-auto">
                <div className="position-relative m-2">
                  <Form.Control
                    type="search"
                    placeholder="Search..."
                    className="serch-feild"
                  />
                  <FiSearch className="searchicon2" />
                </div>
              </div>
            </Stack>
            <Table responsive variant="" className="border-0 custom-table mt-3">
              <thead className="first-td">
                <tr>
                  <th>S.No</th>
                  <th>Report by</th>
                  <th>Report to</th>
                  <th>Reason</th>
                  <th>Description</th>
                  <th>Status </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {reports?.length > 0 ? (
                  reports?.map((report, i) => {
                    return (
                      <tr key={report?._id}>
                        <td>{i + 1 + (page - 1) * limit}</td>
                        <td>{report?.reportBy?.full_name}</td>
                        <td>{report?.reportTo?.full_name}</td>
                        <td>{report?.reason}</td>
                        <td>{report?.desc}</td>
                        <td>
                          <DropdownButton
                            id="dropdown-item-button "
                            title={
                              report?.reportTo?.banned == 0
                                ? "Active"
                                : report?.reportTo?.banned == 1
                                ? "Temporarily Banned"
                                : report?.reportTo?.banned == 2 &&
                                  "Permanently Banned"
                            }
                            className="border-0 secondary-drop-area"
                          >
                            {[
                              { key: 0, text: "Active" },
                              { key: 1, text: "Temporarily Banned" },
                              { key: 2, text: "Permanently Banned" },
                            ]?.map((status, i) => {
                              return report?.reportTo?.banned == status?.key ? (
                                <Dropdown.ItemText>
                                  {status?.text}
                                </Dropdown.ItemText>
                              ) : (
                                <Dropdown.Item
                                  onClick={() =>
                                    handleStatusChange(
                                      report?.reportTo?._id,
                                      status?.key
                                    )
                                  }
                                  as="button"
                                >
                                  {status?.text}
                                </Dropdown.Item>
                              );
                            })}
                          </DropdownButton>
                        </td>
                        <td>
                          <Stack
                            direction="horizontal"
                            className="table-btn"
                            gap={3}
                          >
                            <Button
                              className={`report-action ${
                                report?.status == 1 && "gradient"
                              }`}
                              onClick={() =>
                                report?.status == 1 &&
                                handleClick(report?._id, report?.status)
                              }
                              as="a"
                              variant="success"
                            >
                              {report?.status == 1
                                ? "Close the Action"
                                : "Closed"}
                            </Button>
                            <Button
                              className="report-action"
                              onClick={() => handleDelete(report?._id)}
                              as="a"
                              variant="success"
                            >
                              Delete
                            </Button>
                          </Stack>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>No Reports yet</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <Pagination
              totalStuff={totalReports}
              limit={limit}
              setPage={setPage}
            />
          </Card>
        </Row>
      </Container>
    </>
  );
}
