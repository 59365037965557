import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Card,
  Container,
  Row,
  Form,
  Button,
  Table,
  Badge,
  DropdownButton,
  Stack,
  Dropdown,
} from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSupportStatus,
  getSupports,
} from "../redux/actions/adminActions";
import Pagination from "../components/elements/Pagination";

export default function Support() {
  const dispatch = useDispatch();
  const supports = useSelector((state) => state.supportSlice.supports);
  const totalSupports = useSelector(
    (state) => state.supportSlice.totalSupports
  );
  const limit = 5;
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getSupports({ page, limit, search: searchData }));
  }, [page, searchData]);

  const handleStatusChange = (id, status) => {
    dispatch(changeSupportStatus({ id, status }));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Card className="border-0 p-4 rounded-card user-section custom-table-height">
            <Stack direction="horizontal" gap={3}>
              <div className="p-2">
                <h3>Support</h3>
              </div>
              <div className="p-2 ms-auto">
                <div className="position-relative m-2">
                  <Form.Control
                  onChange={(e)=>setSearch(e.target.value)}
                    type="search"
                    placeholder="Search..."
                    className="serch-feild"
                  />
                  <FiSearch className="searchicon2" />
                </div>
              </div>
            </Stack>
            <Table responsive variant="" className="border-0 custom-table mt-3">
              <thead className="first-td">
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Contact Number</th>
                  {/* <th>Subject</th> */}
                  <th>Message</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {supports?.length > 0 ? (
                  supports?.map((support, i) => {
                    return (
                      <tr key={support?._id}>
                        <td>{i + 1 + (page - 1) * limit}</td>
                        <td className="user-name-ac">
                          <Link to={`/admin/support-detail/${support?._id}`}>
                            {support?.user_id?.full_name}
                          </Link>
                        </td>
                        <td>{support?.user_id?.email}</td>
                        <td>
                          {support?.user_id?.country_code +
                            " " +
                            support?.user_id?.phone_number}
                        </td>
                        {/* <td>Lorem Ipsum is simply</td> */}
                        <td>
                        <p  className="text-short">{support?.message}</p></td>
                        <td>
                          <Badge bg="primary">
                            {support?.status == 0
                              ? "In-processing"
                              : "Resolved"}
                          </Badge>
                        </td>
                        <td>
                          <DropdownButton
                            id="dropdown-item-button "
                            title={
                              support?.status == 0
                                ? "In-processing"
                                : "Resolved"
                            }
                            className="border-0 secondary-drop-area"
                          >
                            {[
                              { key: 0, text: "In-processing" },
                              { key: 1, text: "Resolved" },
                            ]?.map((status) => {
                              return support?.status == status?.key ? (
                                <Dropdown.ItemText>
                                  {status?.text}
                                </Dropdown.ItemText>
                              ) : (
                                <Dropdown.Item
                                  onClick={() =>
                                    handleStatusChange(
                                      support?._id,
                                      status?.key
                                    )
                                  }
                                  as="button"
                                >
                                  {status?.text}
                                </Dropdown.Item>
                              );
                            })}
                          </DropdownButton>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>No Supports yet</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <Pagination
              totalStuff={totalSupports}
              limit={limit}
              setPage={setPage}
            />
          </Card>
        </Row>
      </Container>
    </>
  );
}
