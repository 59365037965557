import React, { useEffect } from "react";
import { Card, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import idcard from "../images/idcard.png";
import selfe from "../images/selfe.png";
import { FiSearch } from "react-icons/fi";
import Layout from "../components/Layout/Layout";
import Stack from "react-bootstrap/Stack";
import { useDispatch, useSelector } from "react-redux";
import { getVerificationUsers } from "../redux/actions/adminActions";

export default function Wallet() {
  return (
    <>
      <Container fluid>
        <Row>
          <Card className="border-0 p-4 rounded-card user-section ">
            <Stack direction="horizontal" gap={3}>
              <div className="p-2">
                <h3>My Credits</h3>
              </div>
              <div className="p-2 ms-auto">
                <div className="position-relative m-2">
                  <Form.Control
                    type="search"
                    placeholder="Search..."
                    className="serch-feild"
                  />
                  <FiSearch className="searchicon2" />
                </div>
              </div>
            </Stack>
            <Table responsive variant="" className="border-0 custom-table mt-3">
              <thead className="first-td">
                <tr>
                  <th>S.No</th>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Total Redeemed Credit Amount</th>
                  <th>Total Credits Sent</th>
                  <th>Total Redeem Coupon </th>
                  <th>Pending Coupon</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>james-smith</td>
                  <td>smith@yopmail.com</td>
                  <td>20</td>
                  <td>20</td>
                  <td>5/10</td>
                  <td>5</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Row>
      </Container>
    </>
  );
}
